import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Form, Button, Row, Col, Container, Spinner } from 'react-bootstrap';
import { Editor } from '@tinymce/tinymce-react'; // Import TinyMCE Editor
import ImageUpload from './ImageUpload';
import { toast } from 'react-toastify';
import { getError } from '../../Utils';
import Axios from 'axios';

const AddArticle = () => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [formData, setFormData] = useState({
    title: '',
    slug: '',
    author: '',
    category: '',
    image: null,
    imageUrl: '',
    description: '',
    metaTitle: '',
    metaDescription: '',
    metaKeywords: '',
  });
  const [categories, setCategories] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef(null); // Ref to access the file input
  const resetProgressBarRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const setImageUrl = (url) => {
    setFormData({
      ...formData,
      imageUrl: url,
    });
  };

  const handleEditorChange = (content) => {
    setFormData({
      ...formData,
      description: content,
    });
  };

  const transformFormData = (formData) => {
    return {
      name: formData.title,
      slug: formData.slug,
      author: formData.author,
      category: formData.category,
      imageUrl: formData.imageUrl,
      description: formData.description,
      metaTitle: formData.metaTitle,
      metaDescription: formData.metaDescription,
      metaKeywords: formData.metaKeywords,
    };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Handle form submission
    const transformedData = transformFormData(formData);

    try {
      const { data } = await Axios.post(
        `${appBaseUrl}/api/articles/article`,
        transformedData
      );
      if (data._id) {
        toast.success('Success! Article added successfully.');
        setFormData({
          title: '',
          slug: '',
          author: '',
          category: '',
          image: null,
          imageUrl: '',
          description: '',
          metaTitle: '',
          metaDescription: '',
          metaKeywords: '',
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Reset the file input field
        }
        if (resetProgressBarRef.current) {
          resetProgressBarRef.current(); // Reset the progress bar
        }
      }
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const fetchData = useCallback(async () => {
    try {
      const { data } = await Axios.get(`${appBaseUrl}/api/categories`);
      setCategories(data);
      const authorData = await Axios.get(`${appBaseUrl}/api/users/authors`);
      setAuthors(authorData?.data);
      setLoading(false);
    } catch (err) {
      toast.error(getError(err));
      setLoading(false);
    }
  }, [appBaseUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleImageUpload = async (blobInfo, success, failure) => {
    const formData = new FormData();
    formData.append('file', blobInfo.blob(), blobInfo.filename());
    formData.append('upload_preset', 'wcpnuesk');

    const response = await Axios.post(
      'https://api.cloudinary.com/v1_1/daqe8jjzi/image/upload',
      formData
    );
    // Extract image URL
    const editorImageUrl = response?.data?.secure_url ?? '';
    return new Promise((resolve) => {
      // Simulate successful upload
      setTimeout(() => {
        resolve(editorImageUrl);
      }, 1000); // Simulate a delay of 1 second
    });
  };

  return (
    <Container id="content" fluid>
      <h1>Create Article</h1>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formTitle">
              <Form.Label>Article Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter article title"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formSlug">
              <Form.Label>Article Slug (In English)</Form.Label>
              <Form.Control
                type="text"
                name="slug"
                value={formData.slug}
                onChange={handleChange}
                placeholder="Enter article slug"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formAuthor">
              <Form.Label>Author</Form.Label>
              <Form.Select
                name="author"
                value={formData.author}
                onChange={handleChange}
              >
                <option value="">Select author</option>
                {authors?.map((author) => {
                  return (
                    <option key={author._id} value={author._id}>
                      {author.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formCategory">
              <Form.Label>Article Category</Form.Label>
              {loading ? (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : (
                <Form.Select
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <option value="">Select category</option>
                  {categories.map((category) => (
                    <option value={category?._id}>{category?.name}</option>
                  ))}
                </Form.Select>
              )}
            </Form.Group>
          </Col>
          <Col md={6}>
            <ImageUpload
              handleFileChange={handleFileChange}
              setImageUrl={setImageUrl}
              fileInputRef={fileInputRef}
              resetProgressBarRef={resetProgressBarRef}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formMetaTitle">
              <Form.Label>Meta Title</Form.Label>
              <Form.Control
                type="text"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={handleChange}
                placeholder="Enter meta title"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formMetaKeywords">
              <Form.Label>Meta Keywords (Comma Separated)</Form.Label>
              <Form.Control
                type="text"
                name="metaKeywords"
                value={formData.metaKeywords}
                onChange={handleChange}
                placeholder="e.g., technology, news, article"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formMetaDescription">
              <Form.Label>Meta Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="metaDescription"
                value={formData.metaDescription}
                onChange={handleChange}
                placeholder="Enter meta description"
              />
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formDescription" className="mb-3">
          <Form.Label>Article Description</Form.Label>
          <Editor
            apiKey="2ao4h0qyfjkzmrv1mwfwut2c9dsnnhuveoxvb5zsivnbdttt" // Optional: Your TinyMCE API key
            value={formData.description}
            init={{
              selector: 'textarea#basic-example',
              height: 500,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'help',
                'wordcount',
                'pageembed',
              ],
              toolbar:
                'undo redo | image | blocks | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | pageembed | help',
              images_upload_handler: handleImageUpload,
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }',
            }}
            onEditorChange={handleEditorChange}
          />
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          disabled={formData.imageUrl ? false : true}
        >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AddArticle;
