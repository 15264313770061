import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';

function RedirectOldSlug() {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { slug } = useParams(); // Grab the slug from the old URL path
  const [newUrl, setNewUrl] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    console.log(`${appBaseUrl}/api/articles/redart/${slug}`);
    const fetchNewUrl = async () => {
      try {
        const response = await axios.get(
          `${appBaseUrl}/api/articles/redart/${slug}`
        ); // Backend API call

        if (response.data && response.data.newUrl) {
          setNewUrl(response.data.newUrl); // This should be the new URL (full path)
        } else {
          setNotFound(true);
        }
      } catch (error) {
        console.error('Error fetching redirect info:', error);
        setNotFound(true);
      }
    };

    fetchNewUrl();
  }, [slug]);

  if (notFound) {
    return <h1>404 - Article Not Found</h1>;
  }

  if (newUrl) {
    return <Navigate to={newUrl} replace />;
  }

  return <h1>Redirecting...</h1>;
}

export default RedirectOldSlug;
