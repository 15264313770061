import React from 'react';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';

function About() {
  return (
    <div className="about">
      <Helmet>
        <title>Lekh - About Us</title>
        <meta
          name="description"
          content={
            'Here you can find lots of articles related to life events, movie reviewes, city news, autobiography, health, Cooking, travel and you can write your own too.'
          }
        />
        <meta
          name="keywords"
          content={
            'hindi blog, bollywood news, best blog in hindi, bollywood new movies, true motivational stories, hindi story reading'
          }
        />
      </Helmet>
      <Container className="my-4">
        <h1>About Us</h1>
        <div>
          <div className="body">
            <Card.Title>Lekh Junction</Card.Title>
            <Card.Text>
              Lekh Junction was founded with a passion for storytelling and a
              desire to create a platform where writers can share their ideas
              and experiences. Our team consists of dedicated professionals who
              strive to deliver exceptional content that resonates with our
              readers.
            </Card.Text>

            <Card.Title>Our Goal</Card.Title>
            <ListGroup>
              <ListGroup.Item>
                Provide valuable insights and information on various topics
              </ListGroup.Item>
              <ListGroup.Item>
                Foster a community of writers and readers who share ideas and
                experiences
              </ListGroup.Item>
              <ListGroup.Item>
                Inspire our readers to explore new perspectives and interests
              </ListGroup.Item>
              <ListGroup.Item>
                Create content that is easy to understand and engaging
              </ListGroup.Item>
            </ListGroup>

            <Card.Title>What We Offer</Card.Title>
            <Card.Text>
              Our website covers a wide range of topics, including:
            </Card.Text>
            <ListGroup>
              <ListGroup.Item>
                Cinema: Latest reviews, news, and analysis
              </ListGroup.Item>
              <ListGroup.Item>
                Sports: Updates, analysis, and expert opinions
              </ListGroup.Item>
              <ListGroup.Item>
                Life: Practical tips, inspiring stories, and self-improvement
                advice
              </ListGroup.Item>
              <ListGroup.Item>
                Parenting: Expert guidance, parenting hacks, and heartwarming
                stories
              </ListGroup.Item>
              <ListGroup.Item>
                Fashion: Trending styles, beauty tips, and lifestyle advice
              </ListGroup.Item>
              <ListGroup.Item>
                Success and Failure: Motivational stories, lessons, and
                inspiring journeys
              </ListGroup.Item>
              <ListGroup.Item>And many more, such as:</ListGroup.Item>
              <ListGroup.Item>Travel and Adventure</ListGroup.Item>
              <ListGroup.Item>Food and Recipes</ListGroup.Item>
              <ListGroup.Item>Technology and Gadgets</ListGroup.Item>
              <ListGroup.Item>Health and Wellness</ListGroup.Item>
              <ListGroup.Item>Business and Entrepreneurship</ListGroup.Item>
              <ListGroup.Item>Culture and Society</ListGroup.Item>
            </ListGroup>

            <Card.Title>Meet Our Team</Card.Title>
            <Card.Text>
              Our team consists of experienced writers, editors, and designers
              who are passionate about creating high-quality content. We're
              committed to delivering exceptional articles that meet our
              readers' expectations.
            </Card.Text>

            <Card.Title>Get Involved</Card.Title>
            <Card.Text>Join our community today!</Card.Text>
            <ListGroup>
              <ListGroup.Item>
                Subscribe to our newsletter for exclusive updates
              </ListGroup.Item>
              <ListGroup.Item>
                Follow us on social media for behind-the-scenes insights
              </ListGroup.Item>
              <ListGroup.Item>
                Share your story or submit an article to be featured on our
                platform
              </ListGroup.Item>
              <ListGroup.Item>
                Contact us with feedback and suggestions
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default About;
