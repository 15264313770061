import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar as BootstrapNavbar, Nav, Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Sidebar() {
  const [name, setName] = useState('John David');
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    setName(userInfo?.name);
  }, []);

  return (
    <BootstrapNavbar id="sidebar" className="d-flex flex-column ps" bg="light">
      <div className="sidebar_blog_1">
        <div className="sidebar-header">
          <div className="logo_section">
            <Link to="/profile">
              <Image
                className="logo_icon img-responsive"
                src="../images/logo.png"
                alt="#"
              />
            </Link>
          </div>
        </div>
        <div className="sidebar_user_info">
          <div className="icon_setting"></div>
          <div className="user_profle_side">
            <div className="user_img">
              <Image
                className="img-responsive"
                src="../images/user_img.jpg"
                alt="#"
                roundedCircle
              />
            </div>
            <div className="user_info">
              <h6>{name}</h6>
              <p>
                <span className="online_animation"></span> Online
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar_blog_2">
        <h4>General</h4>
        <Nav className="flex-column">
          <Nav.Link as={Link} to="/admin/dashboard" className="active">
            <i className="fa fa-dashboard yellow_color"></i>{' '}
            <span>Dashboard</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/articles">
            <i className="fa fa-clone orange_color"></i> <span>Articles</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/categories">
            <i className="fa fa-diamond purple_color"></i>{' '}
            <span>Categories</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/sitemap">
            <i className="fa fa-clone orange_color"></i> <span>Sitemap</span>
          </Nav.Link>
          <Nav.Link as={Link} to="contact.html">
            <i className="fa fa-paper-plane red_color"></i> <span>Contact</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/users">
            <i className="fa fa-clock-o orange_color"></i> <span>Users</span>
          </Nav.Link>
          <Nav.Link as={Link} to="settings.html">
            <i className="fa fa-cog yellow_color"></i> <span>Settings</span>
          </Nav.Link>
        </Nav>
      </div>
    </BootstrapNavbar>
  );
}

export default Sidebar;
