import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { saveAs } from 'file-saver';

const Sitemap = () => {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (!userInfo?.token) {
      navigate('/login');
    }
  }, [navigate]);

  const generateSitemap = async () => {
    setLoading(true);
    setMessage('');
    const page = 1;
    const limit = 1000;
    try {
      // Fetch articles from backend
      const { data } = await axios.get(
        `${appBaseUrl}/api/articles/admin-article?page=${page}&limit=${limit}`
      );
      // Base URL
      const baseUrl = 'https://www.lekhjunction.com';

      // Static pages
      const staticUrls = [
        { loc: '/', changefreq: 'daily', priority: '1.0' },
        { loc: '/contact-us', changefreq: 'yearly', priority: '0.5' },
        { loc: '/about-us', changefreq: 'yearly', priority: '0.5' },
        { loc: '/privacy-policy', changefreq: 'yearly', priority: '0.5' },
        { loc: '/disclaimer', changefreq: 'yearly', priority: '0.5' },
      ];

      // Generate XML content
      let xml = `<?xml version="1.0" encoding="UTF-8"?>\n`;
      xml += `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

      // Add static URLs
      staticUrls.forEach((page) => {
        xml += `  <url>\n`;
        xml += `    <loc>${baseUrl}${page.loc}</loc>\n`;
        xml += `    <changefreq>${page.changefreq}</changefreq>\n`;
        xml += `    <priority>${page.priority}</priority>\n`;
        xml += `  </url>\n`;
      });

      // Add article URLs
      data.articles.forEach((article) => {
        const categorySlug = article.category?.slug || 'general';
        const articleUrl = `${baseUrl}/${categorySlug}/${article.slug}`;

        xml += `  <url>\n`;
        xml += `    <loc>${articleUrl}</loc>\n`;
        xml += `    <changefreq>weekly</changefreq>\n`;
        xml += `    <priority>0.8</priority>\n`;
        xml += `    <lastmod>${new Date(
          article.updatedAt || article.createdAt
        ).toISOString()}</lastmod>\n`;
        xml += `  </url>\n`;
      });

      xml += `</urlset>`;

      // Convert to Blob and trigger download
      const blob = new Blob([xml], { type: 'application/xml' });
      saveAs(blob, 'sitemap.xml');

      setMessage('Sitemap generated and downloaded successfully!');
    } catch (error) {
      console.error('Error generating sitemap:', error);
      setMessage('Failed to generate sitemap.');
    }

    setLoading(false);
  };

  return (
    <Container id="content" fluid>
      <Row className="column_title">
        <Col md={12}>
          <Card className="page_title">
            <Card.Body>
              <Card.Title as="h2">Sitemap</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="column1">
        <Col md={6} lg={3}>
          <Card className="counter_section mb-3">
            <Card.Body className="text-center">
              <i className="fa fa-user yellow_color"></i>
              <Button onClick={generateSitemap} disabled={loading}>
                {' '}
                {loading ? 'Generating...' : 'Generate Sitemap'}
              </Button>
              {message && <p>{message}</p>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Sitemap;
