import React, { useEffect, useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import axios from 'axios';

function RedirectOldUrl() {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { id } = useParams(); // Get the ID from the URL
  const [slug, setSlug] = useState(null);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const fetchSlug = async () => {
      try {
        const response = await axios.get(
          `${appBaseUrl}/api/articles/red/${id}`
        ); // Backend API call

        if (response.data && response.data.newUrl) {
          setSlug(response.data.newUrl); // Set the slug if found
        } else {
          setNotFound(true); // Handle 404
        }
      } catch (error) {
        console.error('Error fetching slug:', error);
        setNotFound(true);
      }
    };
    fetchSlug();
  }, [id]);

  if (notFound) {
    return <h1>404 - Article Not Found</h1>;
  }

  if (slug) {
    return <Navigate to={`/${slug}`} replace />;
  }

  return <h1>Loading...</h1>; // Show a loading message while fetching
}

export default RedirectOldUrl;
